<template>
  <div class="item-product-bag flex-wrap mb-4">
    <div class="container-data-product-bag">
      <router-link
        :to="{
          name: 'ProductDetail',
          params: {
            product: product.url.replace('product-', ''),
            vitrinaId: 'favorite-page-products',
            vitrinaName: 'Productos agregados a favoritos',
          },
          query: { color: product.color },
        }"
      >
        <img
          style="aspect-ratio: 8/9; max-width: 130px;"
          :src="product.imagenes[0]"
          alt=""
        />
      </router-link>
      <div class="content-product-bag w-100" style="max-width: 320px">
        <div
          class="text-product-bag"
          style="max-width: 280px; margin-right: 2rem"
        >
          <router-link
            :to="{
              name: 'ProductDetail',
              params: {
                product: product.url.replace('product-', ''),
                vitrinaId: 'favorite-page-products',
                vitrinaName: 'Productos agregados a favoritos',
              },
              query: { color: product.color },
            }"
          >
            <p>{{ product.producto_titulo }}</p>
          </router-link>
          <p class="data-text-product-bag">
            SKU: {{ product.producto_sku }} / Color: {{ product.color }}
          </p>
          <p class="price-movil-bag d-md-none">
            ${{
              new Intl.NumberFormat('de-DE').format(product.producto_precio)
            }}
          </p>
          <p class="d-none d-md-flex bold">Elige una talla:</p>
          <div class="w-100 d-none my-2 d-md-flex position-relative">
            <div
              v-for="(sizeProduct, index) in product.tallas"
              :key="index"
              class="d-flex pe-3"
            >
              <span
                :title="
                  sizeProduct.stock == 0
                    ? 'Talla agotada'
                    : 'Talla: ' + sizeProduct.name
                "
                :style="
                  missingSize
                    ? `animation-name: missingSize; animation-duration: .5s;`
                    : ''
                "
                class="sizes-product"
                :class="
                  sizeProduct.id == sizeSelected
                    ? 'size-selected'
                    : sizeProduct.stock == 0
                    ? 'size-no-aviable'
                    : ''
                "
                @click="
                  sizeProduct.stock > 0
                    ? [
                        (sizeSelected = sizeProduct.id),
                        (showMsgErrorSize = false),
                      ]
                    : ''
                "
                >{{ sizeProduct.name }}</span
              >
            </div>
            <span v-if="showMsgErrorSize" class="msg-error-size-favorite"
              >Selecciona una talla</span
            >
          </div>
        </div>
        <div class="divider ms-2 d-none d-md-flex" />
      </div>
    </div>
    <div
      class="favorite-price-button price-text-product-bag d-flex justify-content-between"
    >
      <p class="price-product-favorite">
        ${{ new Intl.NumberFormat('de-DE').format(product.producto_precio) }}
      </p>
      <div
        v-if="product.publicado == 1"
        class="w-100 d-flex flex-wrap justify-content-around"
      >
        <div
          class="d-flex flex-wrap mx-3 mb-0 d-md-none position-relative w-100"
        >
          <p class="w-100 mb-2 mt-3 bold">Elige una talla:</p>
          <div
            v-for="(sizeProduct, index) in product.tallas"
            :key="index"
            class="d-flex pe-3"
          >
            <span
              :title="
                sizeProduct.stock == 0
                  ? 'Talla agotada'
                  : 'Talla: ' + sizeProduct.name
              "
              :style="
                missingSize
                  ? `animation-name: missingSize; animation-duration: .5s;`
                  : ''
              "
              class="sizes-product"
              :class="
                sizeProduct.id == sizeSelected
                  ? 'size-selected'
                  : sizeProduct.stock == 0
                  ? 'size-no-aviable'
                  : ''
              "
              @click="
                sizeProduct.stock > 0
                  ? [
                      (sizeSelected = sizeProduct.id),
                      (showMsgErrorSize = false),
                    ]
                  : ''
              "
              >{{ sizeProduct.name }}</span
            >
          </div>
          <span v-if="showMsgErrorSize" class="msg-error-size-favorite"
            >Selecciona una talla</span
          >
        </div>
        <div class="mx-3 w-100" style="max-width: 250px">
          <button
            class="btn-primary w-100 my-3 btn-add-cart-product-detail"
            @click="addProduct()"
          >
            <div v-if="!loading">
              <img
                src="@/assets/icons/shopping-bag.svg"
                alt="Agregar a la bolsa"
              />
              Agregar a la bolsa
            </div>
            <div v-else class="d-flex justify-content-center px-4">
              Cargando...
              <div class="loader" />
            </div>
          </button>
        </div>
      </div>
      <div
        v-else
        class="product-not-aviable-favorite mx-3 mt-3"
        style="max-width: 250px"
      >
        <p class="text-center">
          Producto no disponible, descubre más opciones en
          <router-link
            style="color: black"
            :to="{
              name: 'TiendaCategory',
              params: { category: product.categoria.toLowerCase() },
            }"
            ><strong>{{ product.subcategoria }}</strong></router-link
          >
        </p>
      </div>
    </div>
    <div class="delete-product-bag" @click="deleteProduct()">
      <img
        v-if="!loadingDelete"
        src="@/assets/icons/delete-gray-icon.svg"
        alt="boton cerrar"
      />
      <div v-else class="loader loader-delete" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { sendFBQEvent } from '@/fbq'
import { sendCriteoEventCriteo } from '@/criteo/events'
export default {
  props: {
    product: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      sizeSelected: null,
      missingSize: false,
      loading: false,
      loadingDelete: false,
      showMsgErrorSize: false,
    }
  },
  computed: {
    ...mapState('products', ['productWhislist']),
    ...mapState('cart', ['msgErrorAddProduct']),
  },
  mounted() {
    if (
      this.product.tallas.length == 1 &&
      this.product.tallas[0].stock > 0 &&
      this.product.tallas[0].id == 'UN'
    ) {
      this.sizeSelected = this.product.tallas[0].id
    }
  },
  methods: {
    ...mapActions('cart', ['addToCart']),
    ...mapActions('products', ['deleteProductFavorite']),
    async addProduct() {
      if (this.sizeSelected) {
        this.loading = true
        let dataProductAddToCart = {
          size: this.sizeSelected,
          color: this.product.color,
          sku: this.product.producto_sku,
        }
        await this.addToCart(dataProductAddToCart).then(() => {
          if (!this.msgErrorAddProduct) {
            sendFBQEvent(this, 'AddToCart', {
              product_id: this.product.producto_sku,
              value: this.product.producto_precio,
              content_type: 'product',
              content_name:
                this.product.producto_titulo.toUpperCase() + ' DEREK',
              content_category: this.product.subcategoria.toUpperCase(),
              content_ids:
                this.product.producto_sku +
                this.product.color +
                this.sizeSelected,
              currency: 'COP',
            })
            sendCriteoEventCriteo([
              { event: 'addToCart', item: [this.product.producto_sku] },
            ])
            this.$bvModal.show('modal-add-to-cart')
          } else {
            this.$bvModal.show('modal-message')
          }
        })
        await setTimeout(() => {
          this.loading = false
        }, 500)
      } else {
        this.missingSize = true
        this.showMsgErrorSize = true
        await setTimeout(() => {
          this.missingSize = false
        }, 1000)
      }
    },
    async deleteProduct() {
      this.loadingDelete = true
      await this.deleteProductFavorite({
        producto_sku: this.product.producto_sku,
        color: this.product.color,
      }).then(() => {
        this.loadingDelete = false
      })
    },
  },
}
</script>
